.SpinnerContainer {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ccc;
    opacity: 0.5;
}

.SpinnerElem {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: auto;
    z-index: 999;
}